/* ProfileForm.css */

html,
body {
  height: 100%;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}

.profile-container {
  width: 100%; /* Full width */
  max-width: 500px; /* Max width for the profile form */
  padding: 15px;
  margin: auto;
}

.btn-primary {
  background-color: #271340;
  border-color: #271340;
}

.btn-primary:hover {
  background-color: #271340;
  border-color: #271340;
}

.btn-primary:disabled {
  background-color: #dcd9df;
  border-color: #dcd9df;
  color: #858288;
}

/* You can add more styles if needed */
