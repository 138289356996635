/* LoginPage.css */

html,
body {
  height: 100%;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.login-container {
  width: 100%; /* Full width */
  max-width: 500px; /* Max width for the login form */
  padding: 15px;
  margin: auto;
}

/* Add any additional styles you want to customize */
